/**
 * @deprecated
 * Полная копия скрипта www/js/search.js, за исключением `require`,
 * необходим для того, чтобы использовать скрипт в bem-бандлах вместо assets
 */

/* eslint-disable */
const Bloodhound = require('imports-loader?define=>false!libs/typeahead/bloodhound.min.js');

var town_translit = $('#find').data('town');
if (!town_translit && $('#find_drug').length) {
    town_translit = $('#find_drug').data('town');
}
var WILDCARD = '%QUERY';
var searchPlaceResults;

var $mainPageTown = $('.main_page_search .town');
var $findPlaceInput = $('#find_place');

// Генерирует URL для результатов поиска
function initSearchRedirect() {
    $('#find_drug').off('typeahead:select').on('typeahead:select', function(e, obj) {
        var translit = obj.translit;
        var town_translit;
        if (obj.town__translit) {
            town_translit = obj.town__translit;
        } else {
            town_translit = $('#find').data('town');
        }
        var obj_type = obj.obj_type;
        var group = obj.group;
        var url;
        switch (obj_type) {
            case 'vrach_all':
                url = '/' + town_translit + '/vrach/';
                break;
            case 'lpu_all':
                url = '/' + town_translit + '/lpu/';
                break;
            case 'doctor':
                url = '/' + town_translit + '/vrach/' + translit + '/';
                break;
            case 'lpu':
                url = '/' + town_translit + '/lpu/' + translit + '/';
                break;
            case 'lpuset':
                url = '/' + town_translit + '/set/' + translit + '/';
                break;
            case 'lputype':
                url = '/' + town_translit + '/top/' + translit + '/';
                break;
            case 'servicetype':
                url = '/' + town_translit + '/' + group + '/' + translit + '/';
                break;
            case 'servicecategory':
                url = '/' + town_translit + '/' + group + '/' + translit + '/';
                break;
            case 'serviceclass':
                url = '/' + town_translit + '/' + group + '/' + translit + '/';
                break;
            case 'speciality':
                url = '/' + town_translit + '/' + translit + '/';
                break;
            case 'all':
                url = '/' + town_translit + '/find/?q=' + encodeURIComponent(obj.text);
                break;
            case 'town':
                url = '/' + translit + '/';
                break;
            case 'drug':
                url = 'https://' + PROTABLETKY_DOMAIN + '/' + translit + '/';
                break;
            case 'drugtag':
                url = 'https://' + PROTABLETKY_DOMAIN + '/' + translit + '/';
                break;
            case 'manipulation':
                url = '/' + town_translit + '/lechenie/' + translit + '/';
                break;
            case 'all_spec':
                url = '/' + town_translit + '/vrach/#all_spec';
                break;
            case 'all_lputypes':
                url = '/' + town_translit + '/lpu/';
                break;
            case 'all_towns':
                url = '/';
                break;
            case 'question':
                url = 'https://' + CONSULT_DOMAIN + '/question/' + obj.value + '/';
                break;
            case 'consult_subject':
                url = 'https://' + CONSULT_DOMAIN + '/' + obj.spec_group__translit + '/' + obj.translit + '/';
                break;
            case 'subways':
                var subway_type = obj.value.split(' ');
                if ( subway_type[subway_type.length - 1] === 'Врачи'){
                    url = '/' + town_translit + '/vrach/metro-' + translit + '/';
                } else {
                    url = '/' + town_translit + '/lpu/metro-' + translit + '/';
                }
                break;
            case 'consult_extended_search':
                url = obj.url;
                break;
        }

        var place_translit = $findPlaceInput.data('place_translit');
        var place_type = $findPlaceInput.data('place_type');

        if (place_type == 'district' && place_translit && $.inArray(obj_type, ['speciality', 'lputype', 'vrach_all', 'lpu_all']) > -1) {
            url += 'd-' + place_translit + '/';
        } else if (place_type == 'metro' && place_translit && $.inArray(obj_type, ['speciality', 'lputype', 'vrach_all', 'lpu_all']) > -1) {
            url += 'metro-' + place_translit + '/';
        } else if (place_type && place_translit && obj_type == 'all') {
            url += '&' + place_type + '=' + place_translit;
        }

        if (url) {
            window.location = url;
        }
    });
}

function initDrugSearch() {
    var searchResults = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            cache: false,
            url: '/ajax/' + town_translit + '/find/?obj=drug&q=' + WILDCARD,
            wildcard: WILDCARD
        }
    });

    $('#find_drug').typeahead({
            minLength: 0,
            highlight: true,
            hint: false
        },
        {
            limit: 500,
            name: 'search-results',
            display: 'value',
            source: searchResults,
            templates: {
                suggestion: buildSuggestionElem
            }
        }
    );
    initSearchRedirect();
}

initDrugSearch();


var searchTownResults = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        cache: false,
        url: '/ac/town/?q=%QUERY',
        wildcard: WILDCARD
    }
});


$mainPageTown.typeahead(
    {
        minLength: 0,
        highlight: true,
        hint: false
    },
    {
        limit: 50,
        name: 'search-results',
        display: 'name',
        source: searchTownResults,
        templates: {
            suggestion: function (data) {
                return '<div data-translit="' + data.translit + '">' + data.name + '</div>';
            }
        }
    }
);

function initPlaceSearch() {
    searchPlaceResults = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            cache: false,
            url: '/ajax/' + town_translit + '/place/find/?q=%QUERY',
            wildcard: WILDCARD
        }
    });

    if ($.inArray(town_translit, ['moskva', 'spb']) > -1) {
        $findPlaceInput.attr('placeholder', 'метро или улица');
    } else {
        $findPlaceInput.attr('placeholder', 'район или улица');
    }

    $findPlaceInput.typeahead({
            minLength: 0,
            highlight: true,
            hint: false
        },
        {
            limit: 500,
            name: 'search-results',
            display: 'value',
            source: searchPlaceResults,
            templates: {
                suggestion: function (data) {
                    return '<div class="place_' + data.place_type + '" data-translit="' + data.translit + '">' + data.value + '</div>';
                }
            }
        }
    );
}
initPlaceSearch();

function capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Строит элементы выпадающего списка
function buildSuggestionElem(data) {
    var res, img_50, txt;
    var lpucount = '';
    if (data.obj_type == 'doctor') {
        var img_100 = data.img_100;
        if (img_100) {
            img_100 = '/media/' + img_100;
        } else {
            img_100 = '/static/_v1/pd/icons/ui-kit/photo/no-avatar.svg';
        }
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + '<img alt="" src="' + img_100 + '"><span class="find_doctor_fio">' + data.value + '</span>' + '<span class="ser find_doctor_speclist"> ' + data.speclist.toLowerCase() + '</span>' + '</div>';
    } else if (data.obj_type == 'lpu') {
        img_50 = data.img_50;
        if (img_50) {
            img_50 = '/media/' + img_50;
        } else {
            img_50 = '/static/_v1/pd/icons/default-lpu-avatars/m.svg';
        }
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + '<img alt="" src="' + img_50 + '"><span>' + data.value + '</span></div>';
    } else if (data.obj_type == 'lpuset') {
        img_50 = data.img_50;
        if (img_50) {
            img_50 = '/media/' + img_50;
        }
        if(data.lpus){
            lpucount = '(' + data.lpus + ')';
        }
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + '<img alt="" src="' + img_50 + '"><span>Сеть ' + lpucount + ': ' + data.value + '</span></div>';
    } else if (data.obj_type == 'lputype') {
        var img_lt = '/static/_v1/pd/icons/default-lpu-avatars/m.svg';
        txt = data.value;
        if (data.lpus) {
            txt += ' (' + data.lpus + ')';
        }
        if (data.icons) {
            res = '<div class="find_' + data.obj_type + '_icons" data-translit="' + data.translit + '">' + '<img alt="" src="' + img_lt + '"><span>' + txt + '</span></div>';
        } else {
            res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + '<span>' + txt + '</span></div>';
        }
    } else if (data.obj_type == 'serviceclass') {
        txt = data.value;
        if (data.addresses) {
            txt += ' (' + data.addresses + ')';
        }
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + '<span>' + txt + '</span></div>';
    } else if (data.obj_type == 'drug') {
        var avatar = data.avatar;
        if (avatar) {
            avatar = '/media/' + avatar;
        } else {
            avatar = '/static/_v1/pd/icons/search-drug.png';
        }
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '"><img alt="" src="' + avatar + '"><span>' + data.value + '</span><span class="rates_count">' + data.rates_count_text + '</span></div>';
    } else if (data.obj_type == 'subways') {
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '"><span>' + data.value + '</span></div>';
    }  else if (data.obj_type == 'question') {
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '"><div>Вопрос #' + data.value + '</div></div>';
    }  else if (data.obj_type == 'consult_subject') {
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '"><div>' + data.name + '</div></div>';
    }  else if (data.obj_type == 'consult_extended_search') {
        res = '<div class="find_' + data.obj_type + ' find_all" data-translit="' + data.translit + '"><div>' + data.name + '</div></div>';
    }  else {
        res = '<div class="find_' + data.obj_type + '" data-translit="' + data.translit + '">' + data.value + '</div>';
    }
    return res;
}


$findPlaceInput.on('typeahead:select', function(e, obj){
    var translit = obj.translit;
    var place_type = obj.place_type;
    var place_name = obj.value;
    var old_translit = $findPlaceInput.data('place_translit');
    $findPlaceInput.data('place_translit', translit);
    $findPlaceInput.data('place_type', place_type);
    $findPlaceInput.data('place_name', place_name);
    $('input.find_place_hid').prop('disabled', false);
    $('input.find_place_hid').attr('name', place_type);
    $('input.find_place_hid').val(translit);
    $('#find').typeahead('destroy');
    initSearch();
    if (town_translit) {
        $('#find').data('town', town_translit);
    }
    if ((place_type == 'district' || place_type == 'metro') && old_translit != translit) {
        var place_type_short = (place_type == 'district') ? 'd' : place_type;
        var data_spec = $('#find').data('spec');
        var data_lputype = $('#find').data('lputype');
        var data_baseurl = $('#find').data('baseurl');
        if (data_spec) {
            window.location = '/' + town_translit + '/' + data_spec + '/' + place_type_short + '-' + translit + '/';
        } else if (data_lputype) {
            window.location = '/' + town_translit + '/top/' + data_lputype + '/' + place_type_short + '-' + translit + '/';
        } else if (data_baseurl) {
            window.location = data_baseurl + place_type_short + '-' + translit + '/';
        }
    }
});


function reinitTownSearch(name, translit) {
    $('.finddiv_main form').attr('action', '/' + translit + '/find/');
    $mainPageTown.data('name', name);
    town_translit = translit;
    $('#find').typeahead('destroy');
    $findPlaceInput.val('');
    $findPlaceInput.typeahead('destroy');
    initSearch();
    initPlaceSearch();
    $('#find').data('town', town_translit);
}

$mainPageTown.on('typeahead:select', function(e, obj){
    var translit = obj.translit;
    var name = obj.name;
    reinitTownSearch(name, translit);
});


$mainPageTown.on('typeahead:change', function(e, new_str){
    var town_name = $mainPageTown.data('name');
    if (new_str != town_name) {
        var $suggestions = $('.main_page_search input.town ~ .tt-menu .tt-suggestion');

        if ($suggestions.length == 1) {
            var $first_suggestion = $suggestions.first();
            var translit = $first_suggestion.data('translit');
            var name = $first_suggestion.text();
            $mainPageTown.typeahead('val', $first_suggestion.text());
            reinitTownSearch(name, translit);
        } else {
            $mainPageTown.typeahead('val', town_name);
        }
    }
});


$findPlaceInput.on('typeahead:change', function(e, new_str){
    var place_name = $(this).data('place_name');
    if (new_str != place_name) {
        $findPlaceInput.data('place_translit', '');
        $findPlaceInput.data('place_type', '');
        $findPlaceInput.data('place_name', '');
        $('input.find_place_hid').prop('disabled', true);
        $('#find').typeahead('destroy');
        initSearch();
        if (town_translit) {
            $('#find').data('town', town_translit);
        }
    }
});

$('.finddiv form').submit(function(e){
    if ($('#find').val() == '') {
        $('#find').addClass('error');
        e.preventDefault();
    }
    if ($('.tt-menu:visible').length) {
        $('.tt-menu:visible').hide();
        e.preventDefault();
    }
});

$('#find').on('change keyup', function(e) {
    if ($(this).val() != '') {
        $(this).removeClass('error');
    }
});

$(document).on('mouseover', '.tt-suggestion', function(e) {
    $('.tt-cursor').removeClass('tt-cursor');
    $(this).addClass('tt-cursor');
});
